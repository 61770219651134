import React, {useEffect, useState} from "react";
import {ConcertDAO} from "../ConcertsSection";
import dayjs from "dayjs";
import Masonry from "react-masonry-css";
import {Menu} from "../Menu";

const photoSections = [
    {
        sectionTitle: "2024",
        photos: [
            {id: 7, thumbnailUrl: "/concerts/2024_03.webp", title: "Photo 1"},
            {id: 8, thumbnailUrl: "/concerts/2024_02.webp", title: "Photo 2"},
            {id: 9, thumbnailUrl: "/concerts/2024_01.webp", title: "Photo 3"},
        ],
    },
    {
        sectionTitle: "2023",
        photos: [
            {id: 2, thumbnailUrl: "/concerts/2023_08.webp", title: "Photo 2"},
            {id: 3, thumbnailUrl: "/concerts/2023_07.webp", title: "Photo 3"},
            {id: 4, thumbnailUrl: "/concerts/2023_06.webp", title: "Photo 1"},
            {id: 5, thumbnailUrl: "/concerts/2023_05.webp", title: "Photo 2"},
            {id: 6, thumbnailUrl: "/concerts/2023_04.webp", title: "Photo 3"},
            {id: 7, thumbnailUrl: "/concerts/2023_03.webp", title: "Photo 1"},
            {id: 8, thumbnailUrl: "/concerts/2023_02.webp", title: "Photo 2"},
            {id: 9, thumbnailUrl: "/concerts/2023_01.webp", title: "Photo 3"},
        ],
    },
    {
        sectionTitle: "2022",
        photos: [
            {id: 16, thumbnailUrl: "/concerts/2022_08.webp", title: "Photo 1"},
            {id: 17, thumbnailUrl: "/concerts/2022_07.webp", title: "Photo 2"},
            {id: 18, thumbnailUrl: "/concerts/2022_06.webp", title: "Photo 3"},
            {id: 19, thumbnailUrl: "/concerts/2022_05.webp", title: "Photo 1"},
            {id: 19, thumbnailUrl: "/concerts/2022_04.webp", title: "Photo 1"},
            {id: 19, thumbnailUrl: "/concerts/2022_03.webp", title: "Photo 1"},
            {id: 19, thumbnailUrl: "/concerts/2022_02.webp", title: "Photo 1"},
            {id: 19, thumbnailUrl: "/concerts/2022_01.webp", title: "Photo 1"},
        ],
    },
    {
        sectionTitle: "2021",
        photos: [
            {id: 20, thumbnailUrl: "/concerts/2021_03.webp", title: "Photo 2"},
            {id: 20, thumbnailUrl: "/concerts/2021_02.webp", title: "Photo 2"},
            {id: 20, thumbnailUrl: "/concerts/2021_01.webp", title: "Photo 2"},
        ],
    },
];

export const ConcertsPage: React.FC = () => {
    const [concerts, setConcerts] = useState<ConcertDAO[]>([]);
    const [loading, setLoading] = useState<Boolean>();

    useEffect(() => {
        fetch('https://www.lakymari.cz/api/concerts')
            //fetch('http://api.lakymaricz/api/concerts')
            .then((response) => response.json())
            .then((data) => {
                setConcerts(data);
                setLoading(false);
            })

            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div className="page">
            <Menu/>

            <div className="page__content" style={{maxWidth: '1200px', margin: '0 auto'}}>
                <h1>Koncerty</h1>
                <ul className="timeline">
                    {loading ? (
                        <p>Načítání...</p>
                    ) : (
                        concerts.map((concert: ConcertDAO, index: number) => (
                            <li>
                                <div>
                                    <strong>{dayjs(concert.date).format("DD. MM. YYYY")}</strong><br/>
                                    <strong>{concert.place}</strong>
                                </div>
                            </li>
                        ))
                    )}
                </ul>

                <h1>Galerie uplynulých koncertů</h1>
                {photoSections.map((section, sectionIndex) => (
                    <div key={sectionIndex} style={{marginBottom: "40px"}}>
                        <h2>{section.sectionTitle}</h2>

                        <Masonry
                            breakpointCols={{default: 3, 1100: 2, 700: 1}}
                            className="masonry-grid"
                            columnClassName="masonry-grid_column"
                        >
                            {section.photos.map((photo) => (
                                <img src={photo.thumbnailUrl} alt={photo.title} className="im"/>
                            ))}
                        </Masonry>
                    </div>
                ))}
            </div>
        </div>
    )
}
