import React, { FC, useEffect, useState } from 'react'
import dayjs from "dayjs";

export interface ConcertDAO {
    date: string;
    place: string;
}

const ConcertsSection: FC = () => {

    const [concerts, setConcerts] = useState<ConcertDAO[]>([]);
    const [loading, setLoading] = useState<Boolean>();

    useEffect(() => {
        fetch('https://www.lakymari.cz/api/concerts')
        //fetch('http://api.lakymaricz/api/concerts')
            .then((response) => response.json())
            .then((data) => {
                setConcerts(data);
                setLoading(false);
            })

            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div style={{background: "#FFFFFFC0", padding: '2em 0', maxWidth: '600px', margin: '0 auto', color: '#333'}}>
            <h2>Koncerty</h2>

            {loading ? (
                <p>Načítání koncertů...</p>
            ) : (
                concerts.map((concert: ConcertDAO, index: number) => (
                        <div style={{margin: "2em auto"}}>
                            <strong>{ dayjs(concert.date).format("DD. MM. YYYY") }</strong><br/>
                            <strong>{ concert.place }</strong>
                        </div>
                    )
                )
            )}
        </div>
    )
}

export default ConcertsSection
