import React from "react";
import {Menu} from "../Menu";

export const AboutPage: React.FC = () => {

    return (
        <div className="page section-about">
            <Menu/>

            <div className="page__content" style={{maxWidth: '1200px', margin: '0 auto'}}>
                <h1>O nás</h1>
                <div style={{maxWidth: '800px', margin: '0 auto'}}>
                    <p>
                        Mari Křížová & Lukáš Krupička = <strong>Laky Mari</strong> vzniklo v roce 2020.
                        Před vznikem Laky Mari jsme se setkávali také jako spoluhráči v hard rockové kapele Endless
                        Melody.
                        Příležitostně jsme hráli i ve dvou na akcích různého typu a tak bylo nasnadě, že polehounku
                        začaly vznikat i naše vlastní písně.
                        V roce 2022 jsme vydali <strong>debutové album Lapidárium</strong> společně s několika
                        hostujícími členy.
                    </p>

                    <img src={process.env.PUBLIC_URL + '/assets/about.webp'} style={{maxWidth: '100%'}}/>

                    <p>
                        <strong>A jak je to momentálně?</strong> Hrajeme většinou ve 2-3 lidech - jsme nadšení
                        muzikanti, kteří se shlíží
                        v nejrůznějších hudebních žánrech.
                        Naše autorská tvorba se tak skládá z hudby tradiční - folklórní, ale ráda se pojí s žánry
                        moderními.
                        Proto žánr našich písní označujeme jako <strong>folklórní jazz rock</strong>, který hrajeme v
                        zastoupení dvou
                        hlasů, el. kytary, synth piana a bicích, nicméně také experimentujeme s obměnami nástrojů, zvuků
                        a
                        hlasových rejstříků.
                        Našimi texty se snažíme reflektovat potřebu chránit zvířata a planetu, stát za jejich právy,
                        upozorňovat
                        na společenské a mezilidské problémy.
                    </p>
                    <p>
                        Kromě autorské tvorby máme také repertoáry z písní převzatých.
                        Čistě jazzový (Laky Mari Janz), dále písně z Moravské lidové poezie od Leoše Janáčka (harfa +
                        zpěv), či opravdu mix nejrůznějších písní českých a zahraničních kapel pro příležitosti
                        oslav/svateb (více
                        v sekci "Hudební projekty").
                        Neustále si rozšiřujeme obzory, protože hudba nás baví a žijeme jí/ji.
                    </p>
                </div>

                <h2 style={{margin: '2em 0 1.5em'}}>Naše tvorba</h2>

                <div className="creation">
                    <h3>Zvířecí triptych</h3>

                    <p style={{maxWidth: '800px', margin: '1em auto'}}>V červenci 2024 jsme vydali EP Zvířecí
                        triptych
                        se třemi písněmi (Laň, Pod vrbou, Holubička).</p>

                    <p>
                        <div style={{margin: '2em 0 1em'}}>Obsazení:</div>

                        <h4>Laň</h4>

                        <div className="cast">
                            <div className="cast__item">
                                <div className="name">Mari Křížová</div>
                                <div>zpěv</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Lukáš Krupička</div>
                                <div>harfa, kytara</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Vojtěch Čapek</div>
                                <div>housle</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Josef Kříž</div>
                                <div>violoncello</div>
                            </div>
                        </div>

                        <h4>Pod vrbou</h4>

                        <div className="cast">
                            <div className="cast__item">
                                <div className="name">Mari Křížová</div>
                                <div>zpěv, synth piano</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Lukáš Krupička</div>
                                <div>kytara, vokály</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Matouš Benda</div>
                                <div>bicí</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Sbor Telčísla (ZUŠ Telč)</div>
                                <div>vokály</div>
                            </div>
                        </div>

                        K písni vznikl klip díky výtvarnému oddělení ZUŠ Telč (pod vedením Pavly Doležalové)

                        <h4>Holubička</h4>

                        <div className="cast">
                            <div className="cast__item">
                                <div className="name">Mari Křížová</div>
                                <div>zpěv, synth piano</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Lukáš Krupička</div>
                                <div>kytara, zpěv</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Matouš Benda</div>
                                <div>bicí</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Sbor Telčísla (ZUŠ Telč)</div>
                                <div>vokály</div>
                            </div>
                        </div>

                        K písni vznikl i klip.


                        Ep vyšlo na Spotify, YouTube a dalších plaformách.
                    </p>

                    <img src={process.env.PUBLIC_URL + '/assets/triptych.webp'} alt="Zvířecí triptych"
                         className="im"/>
                </div>

                <div className="creation">
                    <h3>Lapidárium</h3>

                    <p style={{maxWidth: '800px', margin: '1em auto'}}>
                        Debutové album Laky Mari obsahuje 11 písní autorských (hudba & text Mari Křížová) a 3
                        zhudebněné
                        básně Václava Hraběte.
                        Album je z folkovějšího období kapely a v tomto duchu se také nese.
                        Sem tam se však proderou provokativní tóny rockovějšího rázu, nebo příchuť houpavého jazzu,
                        které předznamenali další hudební směřování Laky Mari.
                    </p>

                    <p>
                        <div style={{margin: '2em 0 1em'}}>Obsazení:</div>

                        <div className="cast">
                            <div className="cast__item">
                                <div className="name">Mari Křížová</div>
                                <div>zpěv, kytara, flétny, klarinet</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Lukáš Krupička</div>
                                <div>kytara, zpěv, aranže</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Martin Hons</div>
                                <div>baskytara</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Matouš Benda</div>
                                <div>perkuse</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Anna Raková</div>
                                <div>vokál</div>
                            </div>

                            <div className="cast__item">
                                <div className="name">Jan Sladký</div>
                                <div>vokál, perkuse, flétna</div>
                            </div>
                        </div>

                        <p>Nahráno u Jana Sladkého v Třebíči v roce 2022.</p>

                        <img src={process.env.PUBLIC_URL + '/assets/lapidarium.webp'} alt="Lapidárium"
                             className="im"/>
                    </p>
                </div>
            </div>
        </div>
    )
}
