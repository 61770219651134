import React, {FC, useEffect, useState} from 'react'
import Actuality from "./Actuality";

interface ActualityDAO {
    date: string;
    title: string;
    youtubeUrl: string;
}

const ActualitiesSection: FC = () => {

    const [actualities, setActualities] = useState<ActualityDAO[]>([]);
    const [loading, setLoading] = useState<Boolean>();

    useEffect(() => {
        fetch('https://www.lakymari.cz/api/actualities')
        //fetch('http://api.lakymaricz/api/actualities')
            .then((response) => response.json())
            .then((data) => {
                setActualities(data);
                setLoading(false);
            })

            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <>
            <h2>Novinky</h2>

            <ul className="timeline">
                {loading ? (
                    <p>Načítání novinek...</p>
                ) : (
                    actualities.map((actuality: ActualityDAO, index: number) => (
                        <Actuality
                            key={index}
                            date={actuality.date}
                            title={actuality.title}
                            youtubeUrl={actuality.youtubeUrl}
                        />
                    ))
                )}
            </ul>
        </>
    )
}

export default ActualitiesSection
