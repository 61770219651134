import React, {FC, useEffect, useState} from 'react'
import Reference from "./Reference";

interface ReferenceDAO {
    id: number;
    author: string;
    text: string;
}

const ReferencesSection: FC = () => {

    const [references, setReferences] = useState<ReferenceDAO[]>([]);
    const [loading, setLoading] = useState<Boolean>();

    useEffect(() => {
        fetch('https://www.lakymari.cz/api/references')
            .then((response) => response.json())
            .then((data) => {
                setReferences(data);
                setLoading(false);
            })

            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <>
            <h2>Reference</h2>

            <div className="references">
                {loading ? (
                    <p>Načítání referencí...</p>
                ) : (
                    references.map((reference: ReferenceDAO, index: number) => (
                        <Reference
                            key={index}
                            author={reference.author}
                            text={reference.text}
                        />
                    ))
                )}
            </div>
        </>
    )
}

export default ReferencesSection
