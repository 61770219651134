import React, {FC} from 'react'
import dayjs from "dayjs";

interface ActualityProps {
    date: string,
    title: string,
    youtubeUrl: string;
}

const Actuality: FC<ActualityProps> = ({
   date,
   title,
   youtubeUrl
}) => {
    return (
        <li>
            <div>
                <small>{ dayjs(date).format("DD. MM. YYYY") }</small>

                <p><strong>{ title }</strong></p>

                {youtubeUrl &&
                    <iframe title="youtube"
                            src={ youtubeUrl }
                            style={{ width: "100%", height: "200px" }}
                    ></iframe>
                }
            </div>
        </li>
    )
}

export default Actuality
