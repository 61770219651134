import React, {useEffect, useState} from "react";
import {FiMenu} from "react-icons/fi";
import {NavLink} from "react-router-dom";

export const Menu: React.FC = () => {
    const [scroll, setScroll] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 10);
        });
    })

    return (
        <header className={`headerMain ${scroll ? "sticky" : ""}`}>
            <div className="menu-icon" onClick={toggleMenu}>
                <FiMenu/>
            </div>

            <div className="headerContent">
                <div className="logo-container">
                    <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="Laky Mari logo"/>
                </div>

                <nav className={`main-menu ${menuOpen ? 'open' : ''}`}>
                    <NavLink to="/" onClick={() => setMenuOpen(false)}>Úvod</NavLink>
                    <NavLink to="/o-nas" onClick={() => setMenuOpen(false)}>O nás</NavLink>
                    <NavLink to="/koncerty" onClick={() => setMenuOpen(false)}>Koncerty</NavLink>
                    <NavLink to="/pro-poradatele" onClick={() => setMenuOpen(false)}>Pro pořadatele</NavLink>
                    <NavLink to="/kontakt" onClick={() => setMenuOpen(false)}>Kontakt</NavLink>
                </nav>
            </div>
        </header>
    )
}
