import React, {FC, useEffect, useState} from 'react'
import Member from "./Member";

interface MemberDAO {
    id: number;
    name: string;
    perex: string;
    text: string;
    imageSrc?: string;
}

const MembersSection: FC = () => {

    const [members, setMembers] = useState<MemberDAO[]>([]);
    const [loading, setLoading] = useState<Boolean>();

    useEffect(() => {
        fetch('https://www.lakymari.cz/api/members')
        //fetch('http://api.lakymaricz/api/members')
            .then((response) => response.json())
            .then((data) => {
                setMembers(data);
                setLoading(false);
            })

            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    const getImageForMember = (index: number) => {
        if (index === 0) {
            return process.env.PUBLIC_URL + '/assets/mari.jpg';
        } else if (index === 1) {
            return process.env.PUBLIC_URL + '/assets/lukas.jpg';
        }
        return process.env.PUBLIC_URL + '/assets/matous.jpg';
    }

    return (
        <>
            <h2>Členové</h2>

            <div className="members">
                {loading ? (
                    <p>Načítání členů...</p>
                ) : (
                    members.map((member: MemberDAO, index: number) => (
                        <Member
                            key={index}
                            name={member.name}
                            perex={member.perex}
                            description={member.text}
                            //imageSrc={member.imageSrc || process.env.PUBLIC_URL + '/assets/default.jpg'}
                            imageSrc={ getImageForMember(index) }
                        />
                    ))
                )}
            </div>
        </>
    )
}

export default MembersSection
