import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {BannerLayer, Parallax, ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquareFacebook, faSquareInstagram, faSquareYoutube} from '@fortawesome/free-brands-svg-icons'
import Countdown from "react-countdown";
import Offer from "./components/Offer";
import MembersSection from "./components/MembersSection";
import ReferencesSection from "./components/ReferencesSection";
import ConcertsSection from "./components/ConcertsSection";
import ActualitiesSection from "./components/ActualitiesSection";
import {ConcertsPage} from "./components/pages/Concerts";
import {Menu} from "./components/Menu";
import {AboutPage} from "./components/pages/AboutPage";
import ScrollToTop from "./components/ScrollToTop";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";


interface RendererParams {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}

const renderer = ({days, hours, minutes, seconds, completed}: RendererParams) => {
    if (completed) {
        return ''
    } else {
        return (
            <div style={{
                width: "fit-content",
                borderRadius: ".3em",
                padding: "2em",
                margin: "600px auto 0",
                background: "#000000BB",
                color: "#FFF"
            }}>
                <div style={{fontSize: "1.5em"}}>Do premiéry klipu HOLUBIČKA zbývá</div>
                <div style={{display: "flex", flexDirection: "row", margin: "0 auto", justifyContent: "center"}}>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{days}</div>
                        <span>{declination(days, ["den", "dny", "dní"])}</span>
                    </div>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{hours}</div>
                        <span>{declination(hours, ["hodina", "hodiny", "hodin"])}</span>
                    </div>
                    <div style={{margin: "0 1em", minWidth: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{minutes}</div>
                        <span>{declination(minutes, ["minuta", "minuty", "minut"])}</span>
                    </div>
                    <div style={{margin: "0 1em", width: "4em", textAlign: "center"}}>
                        <div style={{fontSize: "4em"}}>{seconds}</div>
                        <span>{declination(seconds, ["vteřin", "vteřin", "vteřin"])}</span>
                    </div>
                </div>
            </div>
        )
    }
}

const declination = (number: number, options: string[]): string => {
    if (number === 1) {
        return options[0];
    } else if (number > 1 && number < 5) {
        return options[1];
    } else {
        return options[2]
    }
}

const headline: BannerLayer = {
    translateY: [0, 50],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <div className="video-container">
            <video autoPlay loop muted playsInline style={{width: '100%', height: 'auto'}}>
                <source src={process.env.PUBLIC_URL + '/assets/promo.mp4'} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
        </div>
    )
}

const photo: BannerLayer = {
    translateY: [0, -20],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <img src={process.env.PUBLIC_URL + '/assets/DSC_8128.jpg'} alt={"Laky Mari"} style={{width: "100%"}}/>
    )
}

const concerts: BannerLayer = {
    translateY: [0, 15],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <ConcertsSection/>
    )
}

const countdown: BannerLayer = {
    translateY: [0, 30],
    shouldAlwaysCompleteAnimation: true,
    expanded: false,
    children: (
        <Countdown
            date={new Date('2024-03-16T19:00:00.896Z').getTime()}
            renderer={renderer}
        />
    )
}

const Home: React.FC = () => {
    return (
        <ParallaxProvider>
            <Menu/>
            <ParallaxBanner layers={[headline, countdown]} style={{height: '50vw'}}/>

            <Parallax speed={0}>

                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto'}}>
                        <ActualitiesSection/>
                    </div>
                </div>
            </Parallax>

            <Parallax speed={0}>
                <div className="section section--white">
                    <div style={{maxWidth: '1200px', margin: '2em auto'}}>
                        <h2>O nás</h2>

                        <p style={{maxWidth: "600px", margin: '1em auto 0', lineHeight: 1.6}}>
                            Jsme nadšení muzikanti,
                            kteří se shlíží v nejrůznějších hudebních žánrech. Naše autorská tvorba
                            se tak skládá z hudby tradiční - folklórní, ale ráda se pojí s žánry dalšími.
                            Kromě vlastní hudby umíme rozpoutat veselí s party repertoárem nabitým známými hity různých
                            žánrů,
                            dokážeme vytvořit koncertní atmosféru s harfou a zpěvem, nebo doprovodit komorní akce
                            jazzovým repertoárem.
                        </p>
                    </div>
                </div>
            </Parallax>

            <ParallaxBanner layers={[photo, concerts]} style={{height: '100vh'}}/>

            <Parallax speed={0}>
                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto'}}>

                        <MembersSection/>
                    </div>
                </div>
            </Parallax>

            <Parallax speed={0}>
                <div className="section section--white">
                    <div style={{maxWidth: '1200px', margin: '0 auto', display: "flex", flexDirection: "column"}}>
                        <h2>Nabízíme</h2>

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/pen.svg'}
                            title="Autorské písně"
                            text='"Folklórní jazz rock"
                                    Tak nazýváme styl našich autorských písní, které vznikají díky bezuzdné fantazii
                                    Mari Křížové a dostávají formu v rukách a aranžích Lukáše Krupičky.
                                    Hráváme ve složení: el. kytara, perkuse, zpěvy, synth piano'
                            //style={{ textAlign: "end"}}
                            align="right"
                        />

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/microphone.svg'}
                            title="Laky Mari band"
                            text="Máme repertoár složený z oblíbených party coverů českých i zahraničních kapel (od
                                    lidovky po rock).
                                    Hrajeme buď ve dvou (zpěvy, kytara, synth piano, perkuse), nebo se k nám přidává do
                                    třetice bubeník Matouš Benda."
                            //style={{ textAlign: "start", flexDirection: "row" }}
                            align="left"
                        />

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/harp.svg'}
                            title="Harfa & zpěv"
                            text="Možnost koncertního vystoupení s repertoárem z Moravské lidové poezie v písních od
                                    Leoše Janáčka a dalších lidových písní v podání harfy a zpěvu."
                            align="right"
                            //style={{ textAlign: "end" }}
                        />

                        <Offer
                            iconSrc={process.env.PUBLIC_URL + '/assets/guitar.svg'}
                            title="Laky Mari Janz"
                            text="Jazzové standardy v podání Jana Páchy (el.kytara), Mari Křížové (zpěv) a baskytary (Lukáš Krupička)"
                            align="left"
                            //style={{ textAlign: "start", flexDirection: "row" }}
                        />
                    </div>
                </div>

                <div className="section section--green">
                    <div style={{maxWidth: '1200px', margin: '0 auto', display: "flex", flexDirection: "column"}}>
                        <ReferencesSection/>
                    </div>
                </div>

                <div className="section section--white">
                    <div className="footer">
                        <div className="footer__booking">
                            <h2>Laky Mari booking</h2>

                            <p>+420 735 832 022<br/>
                                marikrizovamusic@gmail.com</p>
                            <p>Třebíč, 674 01</p>
                        </div>

                        <div className="footer__social">
                            <a href="https://www.facebook.com/LAKYMARIband" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareFacebook} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://www.instagram.com/lakymari/" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareInstagram} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://bandzone.cz/_117178" className="social-media-anchor">
                                <FontAwesomeIcon icon={faSquareYoutube} fontSize={56} style={{marginLeft: ".6em"}}/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCNcKPv7IPl5emsFK_iNRCxw"
                               className="social-media-anchor">
                                <div style={{marginLeft: "1.2em"}}>Bandzone</div>
                            </a>
                        </div>
                    </div>
                </div>
            </Parallax>
        </ParallaxProvider>
    )
}

const Organizator: React.FC = () => {
    return (
        <div className="page">
            <Menu/>

            <div className="page__content" style={{maxWidth: '1200px', margin: '0 auto'}}>
                <h1>Pro pořadatele</h1>

                <div style={{ textAlign: "left", margin: "2em" }}>
                    <h2>Autorská tvorba</h2>

                    <p>Folklórní jazz rock v podání synt. piana, el. kytary, bicích a dvou hlasů)</p>

                    <h2>Harfa & zpěv</h2>
                    <p>Janáčkova Moravská lidová poezie v písních & další
                        Mari Křížová - zpěv, Lukáš Krupička - harfa</p>

                    <h2>Laky Mari Janz (jazz)</h2>
                    <p>Ve spojení s jazzovým kytaristou Janem Páchou tvoříme také jazzové uskupení.

                        Mari Křížová - zpěv, Jan Pácha - kytara, Lukáš Krupička - baskytara</p>

                    <h2>Cover band</h2>
                    <p>V duu nebo triu můžeme zahrát na vaší akci nejrůznější písně stylů
                        rock/pop/jazz/swing/funk/lidovka.</p>

                </div>

                <h2>Stage plan</h2>
                <p>Alternativa stage planu v případě hraní ve dvou (Mari & Luky)</p>

                <img src={process.env.PUBLIC_URL + '/assets/stageplan_2.webp'}
                     style={{maxWidth: "800px", margin: "2em"}}/>

                <p>Varianta hraní v triu (s bicími) </p>

                <img src={process.env.PUBLIC_URL + '/assets/stageplan_3.webp'}
                     style={{maxWidth: "800px", margin: "2em"}}/>

                <p>Stage plan pro Laky Mari Janz (jazzové trio):</p>

                <img src={process.env.PUBLIC_URL + '/assets/stageplan_janz.webp'}
                     style={{maxWidth: "800px", margin: "2em"}}/>
            </div>
        </div>
    )
}

const Contact: React.FC = () => {
    return (
        <div className="page">
            <Menu/>

            <div className="page__content" style={{maxWidth: '1200px', margin: '0 auto'}}>
                <h1>Kontakt</h1>

                <img src={process.env.PUBLIC_URL + '/assets/booking.webp'} style={{ maxWidth: "800px", margin: "2em" }} />

                <h2>Ozvěte se</h2>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "2em",
                    gap: "2em",
                    justifyContent: "center"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".8em",
                        background: "rgba(0, 0, 0, .1)",
                        padding: "2em",
                        borderRadius: ".5em",
                        width: "200px"
                    }}>
                        <FontAwesomeIcon icon={faPhone} fontSize={56}/>
                        +420 735 832 022
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: ".8em",
                        background: "rgba(0, 0, 0, .1)",
                        padding: "2em",
                        borderRadius: ".5em",
                        width: "200px"
                    }}>
                        <FontAwesomeIcon icon={faEnvelope} fontSize={56}/>
                        marikrizovamusic@gmail.com
                    </div>
                </div>

                <h2 style={{marginTop: "2em"}}>Sociální sítě</h2>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2.5em",
                    justifyContent: "center",
                }}>

                    <a href="https://www.facebook.com/LAKYMARIband" className="social-media-anchor">
                        <FontAwesomeIcon icon={faSquareFacebook} fontSize={56} style={{color: "#333"}}/>
                    </a>
                    <a href="https://www.instagram.com/lakymari/" className="social-media-anchor">
                        <FontAwesomeIcon icon={faSquareInstagram} fontSize={56} style={{color: "#333"}}/>
                    </a>
                    <a href="https://bandzone.cz/_117178" className="social-media-anchor">
                        <FontAwesomeIcon icon={faSquareYoutube} fontSize={56} style={{color: "#333"}}/>
                    </a>
                </div>
            </div>
        </div>
    )
}

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <div className="App">
                <main>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/o-nas" element={<AboutPage/>}/>
                        <Route path="/koncerty" element={<ConcertsPage/>}/>
                        <Route path="/pro-poradatele" element={<Organizator/>}/>
                        <Route path="/kontakt" element={<Contact/>}/>
                    </Routes>
                </main>
            </div>
        </BrowserRouter>
    );
};

export default App;
